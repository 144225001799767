import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import "../components/all.sass"

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  date,
  author,
  featuredimage,
  tags,
  title,
  rewnum,
  rewrat,
  slug,
  helmet
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div className="content is-small "> 
    <section className="section">
      {helmet || ""} 
      <div className="container" style={{ paddingLeft: '10%',
      paddingRight: '10%',
      maxWidth:"75rem"
       }}>
        <div className="hero-body">
          <div className="has-text-centered">
            <h1 className="title is-2 has-text-centered">{title}</h1>
            <p>
              Author: 
              <span> <Link rel="nofollow" to="/about/">{author}</Link> <br></br>Updated on {date}
              </span>
            </p>
          </div>
                 <PostContent content={content} />
          {tags && tags.length ? (
            <div style={{ marginTop: `4rem` }}>
              <h4>Category</h4>
                {tags.map(tag => (
                  <p key={tag + `tag`}>
                    <Link rel="nofollow" to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                  </p>
                ))}
              <div>
        <span >{title} reviews:  </span><span>   </span>
      <span >{rewrat}</span><span> out of </span>
      <span> {rewnum}</span>
      <div className="rater has-text-left">
<span>☆</span><span>☆</span><span>☆</span><span>☆</span><span>☆</span>
</div>
      </div>
            </div>
          ) : null}
        </div>

       </div>
    </section>
    </div>

  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  rewrat: PropTypes.number,
  rewnum: PropTypes.number,
  author: PropTypes.string,
  slug : PropTypes.string,
  helmet: PropTypes.object
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        date={post.frontmatter.date}
        author={post.frontmatter.author}
        featuredimage={post.frontmatter.featuredimage}
        title={post.frontmatter.title}
        rewrat={post.frontmatter.rewrat}
        rewnum={post.frontmatter.rewnum}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta property="og:type" content="article"/>
            <meta property="og:url" content={`https://zenresume.com/blog/${post.frontmatter.slug}/`}/>
            <meta property="og:title" content={`${post.frontmatter.title}`}/>
            <meta property="og:description" content={`${post.frontmatter.description}`}/>
            <meta property="og:image" content={`https://zenresume.com${post.frontmatter.featuredimage.childImageSharp.fluid.src}`}/>
            <script type="application/ld+json">
              {`
{
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "name": "${post.frontmatter.title}",
    "description":"${post.frontmatter.description}",
    "@id": "${post.frontmatter.title}#article"
  },
  "headline": "Article headline",
  "image": "${post.frontmatter.featuredimage.childImageSharp.fluid.src}",
  "datePublished": "${post.frontmatter.date}",
  "dateModified": "${post.frontmatter.date}",
  "author": {
    "@type": "Person",
    "name": "${post.frontmatter.author}"
  },
  "publisher":{
    "@type":"Organization",
    "name" : "Zenresume",
    "sameAs": [ "",
    ""],
      "logo": {
      "@type": "ImageObject",
      "url": "https://zenresume.com/img/favicon-32x32.png"
    }
  },
  "description": "${post.frontmatter.title}"
}
`}
            </script>
            <script type="application/ld+json">{`
{
   "@context":"https://schema.org/",
   "@type":"CreativeWorkSeries",
   "name": "${post.frontmatter.title}",
   "aggregateRating":{
      "@type":"AggregateRating",
      "ratingValue":${post.frontmatter.rewrat},
      "reviewCount":${post.frontmatter.rewnum}
    }
   
}
`}</script>
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        author
        rewrat
        slug
        rewnum
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 720, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
